import React from 'react'

const NoFoundData = ({title}) => {
  return (
    <div>
        <div className="h-[80vh] flex justify-center flex-col text-center max-[1024px]:h-[67vh]">
          <h1 className="text-[120px] text-black max-[768px]:text-[94px] mb-0">404</h1>
          <h2 className="text-4xl mb-1 text-black font-semibold max-[768px]:text-[25px]">
            {title}
          </h2>
          <div className="flex justify-center mt-3" onClick={()=> window.location.href("/")}>
              <button className="text-xl underline font-medium bg-[#F06939] text-white w-[220px] rounded-md h-[60px] mt-2">
                Go Back
              </button>
          </div>
        </div>
    </div>
  )
}

export default NoFoundData