import React, { memo, useState } from "react";
import Skeleton from "react-loading-skeleton";

const Blog = memo(({ data }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  setTimeout(() => {
    setImageLoaded(true);
  }, 1500);
  return (
    <a href={`/blog/${data?.title?.replace(/\s+/g, "-")}`}>
      <div
        className="bg-[#fff] cursor-pointer p-3 border border-[#C8C8C8] h-full"
        // onClick={() => {
        //   const formattedTitle = data?.title?.replace(/\s+/g, "-");
        //   window.location.href = `/blog/${formattedTitle}`;
        // }}
      >
        <div className="h-[200px] sm:h-[250px]">
          {imageLoaded ? (
            <img
              src={data?.image}
              alt={data?.title}
              className="!h-full w-full object-cover"
            />
          ) : (
            <Skeleton
              height="100%"
              width="100%"
              style={{
                backgroundColor: "#e0e0e0",
                opacity: 0.6,
              }}
            />
          )}
        </div>
        <div className="">
          <p className="mb-0 mt-2 me-3 text-[14px] text-gray-500">
            {new Date(data?.created_at).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </p>
          <h2 className="font-bold mb-1 text-[20px] max-[1024px]:text-[18px] line-clamp-2 leading-[30px]">
            {data?.title}
          </h2>
          <p
            className=" text-[17px] line-clamp-2"
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
          ></p>
        </div>
      </div>
    </a>
  );
});

export default Blog;
