import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import BetweenContent from "../Components/ads/BetweenContent";
import EndOfArticle from "../Components/ads/EndOfArticle";
import CelebrityCard from "../Components/Common/celebrity-card";
import BackToTop from "../Components/backToTop";
import decrypt from "../Components/Common/DecryptData";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";

const CelebrityData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ");

  const [celebrityData, setCelebrityData] = useState();
  const [celebrities, setCelebrities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  useEffect(() => {
    const fetchCelebrityData = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}api/celebrity/${formattedTitle}`
        );

        const celebrityData = response.data;

        const decryptedDataString = decrypt(
          celebrityData.iv,
          celebrityData.end
        );
        const decryptedCelebrityData = JSON.parse(decryptedDataString);

        setCelebrityData(decryptedCelebrityData?.data || []);
      } catch (error) {
        console.error("Error fetching celebrity data:", error);
      }
    };

    fetchCelebrityData();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [formattedTitle]);

  useEffect(() => {
    const fetchOtherCelebrityData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-celebrity?page=1&limit=50`
        );

        const encryptedData = response.data;

        const decryptedDataString = decrypt(
          encryptedData.iv,
          encryptedData.end
        );
        const decryptedCelebritiesData = JSON.parse(decryptedDataString);

        setCelebrities(decryptedCelebritiesData?.data || []);
      } catch (error) {
        console.error("Error fetching other celebrities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOtherCelebrityData();
  }, []);

  const handleCardClick = useCallback(
    (newFormattedTitle) => {
      let clickCount = localStorage.getItem("clickCount") || 0;
      localStorage.setItem("clickCount", Number(clickCount) + 1);
      navigate(`/celebrities-data/${newFormattedTitle}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [navigate]
  );

  const filteredCelebrities = useMemo(() => {
    return celebrities.filter((cel) => cel.title !== formattedTitle);
  }, [celebrities, formattedTitle]);

  return (
    <>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}

      <div className="px-4">
        {loading ? (
          <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
            <div className="loader2 my-5"></div>
          </div>
        ) : (
          <div className="container max-w-7xl mx-auto pt-[2rem] lg:px-0">
            <h1 className="flex justify-center text-[#000] md:text-[33px] font-semibold text-[22px] text-center md:mb-5 mb-3">
              {celebrityData?.title}
            </h1>
            <table className="min-w-full border-2 border-gray-400 md:table hidden">
              <thead className="table-header-group">
                <tr className="border-2 border-black md:table-row">
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                    ID
                  </th>
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                    Image
                  </th>
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                    Name
                  </th>
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                    Age
                  </th>
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                    Net Worth
                  </th>
                </tr>
              </thead>
              <tbody className="table-row-group">
                {celebrityData?.blogDetails?.map((item, index) => (
                  <tr
                    key={item?.id}
                    className="bg-white border-b-2 border-gray-400 cursor-pointer"
                    onClick={() => {
                      const Title = item?.name?.replace(/\s+/g, "-");
                      window.location.href = `/celebrities-details/${querySlug}/${Title}`;
                    }}
                  >
                    <td className="md:p-3 px-1 py-2 text-[#F06939] font-bold md:text-[20px] text-[13px]">
                      #{index + 1}
                    </td>
                    <td className="md:p-2 md:px-3 px-1 py-2 text-gray-700">
                      <img
                        src={item?.image}
                        alt=""
                        className="h-20 w-28 object-cover object-top"
                        loading="lazy"
                      />
                    </td>
                    <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                      {item.name}
                    </td>
                    <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                      {item.age}
                    </td>
                    <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                      {item.net_worth}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="md:hidden block">
              <div className="grid min-[500px]:grid-cols-2 grid-cols-1 min-[500px]:gap-5 gap-3">
                {celebrityData?.blogDetails?.map((item, index) => (
                  <div
                    key={index}
                    className="border border-[#cacaca] p-2"
                    onClick={() => {
                      const Title = item?.name?.replace(/\s+/g, "-");
                      window.location.href = `/celebrities-details/${querySlug}/${Title}`;
                    }}
                  >
                    <div className="w-full min-[500px]:h-[250px]">
                      <img
                        src={item?.image}
                        alt=""
                        className="w-full h-[400px] object-cover object-top"
                      />
                    </div>
                    <p className=" text-[17px] font-semibold mt-1">
                      {item?.name}
                    </p>
                    <div className="flex">
                      {item?.net_worth && (
                        <p className="text-[17px] font-medium mr-1">
                          <b> Net Worth : </b> {item?.net_worth + " | "}
                        </p>
                      )}
                      {item?.age && (
                        <p className="text-[17px] ">
                          <b> Age : </b>
                          {item?.age}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <h3
                className="text-center mt-8 text-[30px] font-semibold border-b border-gray-500"
                style={{ fontFamily: "math" }}
              >
                Celebrity Insider
              </h3>
            </div>
            <BetweenContent />
            <div className="grid lg:grid-cols-3 min-[500px]:grid-cols-2 grid-cols-1 md:mt-16 min-[500px]:mt-10 mt-3 sm:gap-9 gap-7">
              {filteredCelebrities.map((celebrity, index) => (
                <React.Fragment key={index}>
                  <CelebrityCard
                    data={celebrity}
                    handleCardClick={handleCardClick}
                  />
                  {index === 5 && (
                    <div className="lg:col-span-3 sm:col-span-2 col-span-1 mt-4">
                      <EndOfArticle />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        <BackToTop />
      </div>
    </>
  );
};

export default CelebrityData;
