import React, { memo, useEffect, useState } from "react";
import SubBlogCard from "../Common/SubBlogCard";
import decrypt from "../Common/DecryptData";
import axios from "axios";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const SubBlog = memo(({ title, link, url, formattedTitle }) => {
  const [blogData, setBlogData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const allBlogData = async () => {
      try {
        const response = await axios.get(url);
        if (response?.data) {
          const { iv, end } = response.data;
          const decryptedResponse = decrypt(iv, end);
          const parsedData = JSON.parse(decryptedResponse);
          const shuffledBlogs = shuffleArray(parsedData.data).slice(0, 15);
          const filteredBlogs =
            formattedTitle && link === "headline"
              ? parsedData?.data?.filter(
                  (line) =>
                    line?.title?.trim().toLowerCase() !==
                    formattedTitle?.trim().toLowerCase()
                )
              : shuffledBlogs?.filter((blog) => blog?.slug !== formattedTitle);

          setBlogData(formattedTitle ? filteredBlogs : shuffledBlogs);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    // Only fetch data if not on mobile
    if (!isMobile) {
      allBlogData();
    }
  }, [isMobile, formattedTitle, link, url]);

  // if (isMobile) return null;

  return (
    <div className="max-[1100px]:px-3 p-4 h-full">
      <div className="flex">
        <h3
          className="font-semibold text-[28px] border-b border-[#000]"
          style={{ fontFamily: "Playfair Display" }}
        >
          {title}
        </h3>
      </div>
      {blogData?.map((blog, index) => {
        const formattedTitle = blog?.title?.replace(/\s+/g, "-");
        return (
          <SubBlogCard
            key={blog?.id || index}
            link={link}
            formattedTitle={formattedTitle}
            blog={blog}
            index={index}
          />
        );
      })}
    </div>
  );
});

export default SubBlog;
