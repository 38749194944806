import React, { useEffect, useState } from "react";
import BackToTop from "../Components/backToTop";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import ParallaxCode from "../Components/ads/ParallaxCode";
import NoFoundData from "../Components/Common/noFoundData";
import StickyAd from "../Components/Common/StickywithSubBLog";
import EndOfArticle from "../Components/ads/EndOfArticle";
import SocialShareButtons from "../Components/Common/SocialShareButtons";
import BlogWithParallax from "../Components/Common/BlogWithParallax";
import decrypt from "../Components/Common/DecryptData";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";

const SingleHeadline = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [headlineData, setHeadlineData] = useState({});
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  useEffect(() => {
    const getOneBlog = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${Base_Url}api/more-headline/${formattedTitle}`
        );

        const { iv, end } = res.data;

        const decryptedDataString = decrypt(iv, end);
        const decryptedData = JSON.parse(decryptedDataString);

        setLoading(false);
        if (decryptedData) {
          setHeadlineData(decryptedData?.data);
        }
      } catch (error) {
        setLoading(false);
        setNotFound(true);
      }
    };

    if (formattedTitle) {
      getOneBlog();
    }
  }, [formattedTitle]);

  return (
    <>
    {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      <div className="container max-w-7xl mx-auto px-4">
        {!notFound ? (
          <>
            {loading ? (
              <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
                <div className="loader2 my-5"></div>
              </div>
            ) : (
              <div className="">
                <div className="flex justify-center pt-5">
                  <div className="text-center mt-3 h-full ">
                    <h1 className="text-[#000] px-[20px] text-3xl leading-snug font-semibold max-[1024px]:text-center max-[1024px]:!leading-[60px] max-[1024px]:px-0 max-[768px]:text-2xl max-[768px]:!leading-normal">
                      {headlineData?.title}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-end">
                  {headlineData?.sourceBy && (
                    <p className="text-[14px] text-gray-500 mt-2 mr-1">
                      {headlineData?.sourceBy + " | "}
                    </p>
                  )}
                  {headlineData?.created_at && (
                    <p className="text-[14px] text-gray-500 mt-2">
                      {new Date(headlineData?.created_at).toLocaleDateString(
                        "en-US",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </p>
                  )}
                </div>
                <div className="flex justify-center lg:h-[550px] mb-5 w-full">
                  <div className="w-full">
                    <img
                      src={headlineData?.image}
                      className="h-full w-full object-cover"
                      alt={headlineData?.image}
                    />
                  </div>
                </div>
                <SocialShareButtons blogTitle={headlineData.title} />

                <ParallaxCode />
                <div className="flex gap-5">
                  <div className="lg:w-[70%] w-full headline-description">
                    {headlineData?.description && (
                      <BlogWithParallax
                        description={headlineData?.description}
                      />
                    )}
                  </div>
                  <StickyAd
                    url={`${Base_Url}api/more-headline`}
                    link="headline"
                    StickyContent={<EndOfArticle />}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <NoFoundData title="Ooops... No Headline found!" />
        )}
        <BackToTop />
      </div>
    </>
  );
};

export default SingleHeadline;
