// components/SocialShareButtons.js

import React, { useState } from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaLink } from "react-icons/fa";

const SocialShareButtons = ({ blogTitle }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  return (
    <div className="flex justify-center space-x-4 my-6">
      <button
        className="bg-blue-600 text-white rounded-full px-4 py-2 hover:bg-blue-700 hover:scale-105 hover:shadow-lg transition transform duration-300 ease-in-out"
        onClick={() =>
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
            "_blank"
          )
        }
      >
        <FaFacebookF />
      </button>
      <button
        className="bg-blue-400 text-white rounded-full px-4 py-2 hover:bg-blue-500 hover:scale-105 hover:shadow-lg transition transform duration-300 ease-in-out"
        onClick={() =>
          window.open(
            `https://twitter.com/intent/tweet?url=${window.location.href}&text=${blogTitle}`,
            "_blank"
          )
        }
      >
        <FaTwitter />
      </button>
      <button
        className="bg-blue-800 text-white rounded-full px-4 py-2 hover:bg-blue-900 hover:scale-105 hover:shadow-lg transition transform duration-300 ease-in-out"
        onClick={() =>
          window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
            "_blank"
          )
        }
      >
        <FaLinkedinIn />
      </button>
      <button
        className="bg-gray-600 text-sm text-white rounded-full px-4 py-2 hover:bg-gray-700 hover:scale-105 hover:shadow-lg transition transform duration-300 ease-in-out"
        onClick={handleCopyClick}
      >
        {copied ? "Copied!" : <FaLink />}
      </button>
    </div>
  );
};

export default SocialShareButtons;
