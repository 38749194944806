import { useEffect, useRef } from "react";

// Function to simulate BetweenContent component as raw HTML
const renderBetweenContent = () => {
  return `
    <div class="flex justify-center my-5">
      <div
        data-id="rediads_postbid_el"
        data-adslot="/23055822891/millionairclubs.in/Between_Content"
        data-size-desktop="[[300,250]]"
        data-size-mobile="[[300,250]]"
        data-ad-refresh="true"
      ></div>
    </div>
  `
};

// CSS to ensure images take up 100% height and width
const injectStyles = `  
    img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }

    p ,ul,li{
        font-size: clamp(0.5rem, 4vw, 1rem); /* Responsive from 2rem to 3rem */
    }
    h1 {
        font-size: clamp(1rem, 6vw, 2rem); /* Responsive from 2rem to 3rem */
    }

    h2 {
        font-size: clamp(1.8rem, 4vw, 2.5rem); /* Responsive from 1.8rem to 2.5rem */
    }

    h3 {
        font-size: clamp(1rem, 3.5vw, 1.5rem); /* Responsive from 1.6rem to 2rem */
    }

    h4 {
        font-size: clamp(1rem, 3.5vw, 1.5rem); /* Responsive from 1.4rem to 1.75rem */
    }

    .headline-description {
        position: relative;
    }
`;
const BlogWithParallax = ({ description }) => {
  const BetweenContentAd = `{{BETWEEN_CONTENT_AD}}`;
  const parts = description?.split(BetweenContentAd);
  const shadowRootRef = useRef(null); // Ref for the host element

  useEffect(() => {
    const renderContent = () => {
      if (shadowRootRef.current && !shadowRootRef.current.shadowRoot) {
        const shadowRoot = shadowRootRef.current.attachShadow({ mode: "open" });

        const container = document.createElement("div");

        const styleEl = document.createElement("style");
        styleEl.textContent = injectStyles;

        shadowRoot.appendChild(styleEl);

        parts?.forEach((part, index) => {
          const partDiv = document.createElement("div");
          partDiv.innerHTML = part;
          container.appendChild(partDiv);

          if (index < parts.length - 1) {
            const betweenContentWrapper = document.createElement("div");
            betweenContentWrapper.innerHTML = renderBetweenContent();
            container.appendChild(betweenContentWrapper);
          }
        });

        shadowRoot.appendChild(container);

        if (window.RediAds) {
          try {
            window.RediAds.refreshAds();
          } catch (error) {
            console.error("Error refreshing ads:", error);
          }
        }
      }
    };

    // Ensure description is valid before rendering
    if (parts && parts.length > 0) {
      renderContent();
    } else {
      console.warn("No valid description provided.");
    }
  }, [description, parts]); // Ensure effect runs when description changes

  return <div ref={shadowRootRef} className="headline-description"></div>; // Attach shadow root to this element
};

export default BlogWithParallax;
