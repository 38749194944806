import React, { useEffect } from "react";

const BetweenContent = () => {
  useEffect(() => {
    if (window.RediAds) {
      window.RediAds.refreshAds();
    }
  }, []);

  return (
    <div className="flex justify-center my-5">
      <div
        data-id="rediads_postbid_el"
        data-adslot="/23055822891/millionairclubs.in/Between_Content"
        data-size-desktop="[[300,250]]"
        data-size-mobile="[[300,250]]"
        data-ad-refresh="true"
      ></div>
    </div>
  );
};

export default BetweenContent;
