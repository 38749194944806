import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ParallaxCode = () => {
  useEffect(() => {
    if (window.RediAds) {
      window.RediAds.refreshAds();
    }
  }, []);

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div
      style={{
        height: "340px",
        textAlign: "center",
      }}
      className="parallax_container"
    >
      <div className="parallax_container_one">
        <div className="parallax_container_two" style={{ top: "20px" }}>
          <div className="flex justify-center gap-5">
            <div
              data-adslot="/23055822891/millionairclubs.in/Parallex_AD"
              data-size-desktop="[[320,480],[300,1050],[300,600],[120,600],[160,600],[125,600]]"
              data-size-mobile="[[320,480]]"
              data-ad-refresh="true"
              data-ad-type="fyling-carpet"
            ></div>
            <div
              className="hidden lg:block"
              data-adslot="/23055822891/millionairclubs.in/Parallex_AD"
              data-size-desktop="[[320,480],[300,1050],[300,600],[120,600],[160,600],[125,600]]"
              data-size-mobile="[[320,480]]"
              data-ad-refresh="true"
              data-ad-type="fyling-carpet"
            ></div>
            {isHomePage && (
              <div
                className="hidden lg:block"
                data-adslot="/23055822891/millionairclubs.in/Parallex_AD"
                data-size-desktop="[[320,480],[300,1050],[300,600],[120,600],[160,600],[125,600]]"
                data-size-mobile="[[320,480]]"
                data-ad-refresh="true"
                data-ad-type="fyling-carpet"
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParallaxCode;
