import React from "react";
import { mainBlogData } from "../Components/data/data";
import { useParams } from "react-router-dom";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import BetweenContent from "../Components/ads/BetweenContent";
import ParallaxCode from "../Components/ads/ParallaxCode";
import EndOfArticle from "../Components/ads/EndOfArticle";

const HomeTopBlog = () => {
  const { title } = useParams();

  if (title === "The-Richest-Self-Made-Millionaires") {
    return (
      <div>
        <div className="flex justify-center flex-col gap-10">
          <div className="">
            <h1
              className="text-white bg-black font-bold px-2 pt-10  text-center text-[25px] sm:text-[28px] md:text-[30px] lg:text-[42px]"
              style={{ fontFamily: "Playfair Display" }}
            >
              {mainBlogData.title}
            </h1>
            <p className="sm:text-[15px] text-[14px] bg-black text-white  text-center">
              {new Date(mainBlogData.created_at).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </p>
            <img
              src={mainBlogData.image}
              alt=""
              className="h-[20vh] md:h-full w-full object-cover object-top"
            />
          </div>
          <ParallaxCode />
          <div className="flex justify-between">
            <div className="hidden xl:block">
              <BetweenContent />
            </div>
            <div className="flex justify-center">
              <div className="px-[20px] lg:px-[100px] max-w-7xl  mt-3">
                <h2 className="border-b-2 font-medium md:text-2xl text-center pb-10">
                  {mainBlogData.mainDescription}
                </h2>
                <p
                  className="md:mt-5 mt-3 border-b-2 pb-10 lg:text-[18px] text-[15px]"
                  dangerouslySetInnerHTML={{
                    __html: mainBlogData.description,
                  }}
                ></p>
                <BelowFeaturedImages />
                {mainBlogData.billionaireData.map((data, index) => (
                  <React.Fragment key={index}>
                    <div
                      key={index}
                      className="flex flex-col gap-2 border-b-2 pb-10"
                    >
                      <h2 className="text-3xl font-bold mt-5">{data.name}</h2>
                      <p className="text-lg">
                        Age: <span className="font-semibold">{data.Age}</span>
                      </p>
                      <p className="text-lg">
                        Net worth:{" "}
                        <span className="font-semibold">{data.NetWorth}</span>
                      </p>
                      <p className="text-lg">
                        Source of wealth:{" "}
                        <span className="font-semibold">
                          {data.SourceOfWealth}
                        </span>
                      </p>
                      <p className="text-lg">
                        Self-made score:{" "}
                        <span className="font-semibold">
                          {data.SelfMadeScore}
                        </span>
                      </p>
                      <p className="font-normal text-lg">{data.description}</p>
                    </div>
                    {index === 1 && (
                      <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                        <BetweenContent />
                      </div>
                    )}
                    {index === 3 && (
                      <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                        <EndOfArticle />
                      </div>
                    )}
                    {index === 5 && (
                      <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                        <BetweenContent />
                      </div>
                    )}
                    {index === 7 && (
                      <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                        <EndOfArticle />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="hidden xl:block">
              <BetweenContent />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[70vh] flex justify-center flex-col text-center max-[1024px]:h-[67vh]">
      <h1 className="text-[90px] text-black max-[768px]:text-[94px] max-[768px]:mb-0">
        404
      </h1>
      <h2 className="text-3xl mb-1 text-black font-semibold max-[768px]:text-[25px]">
        Ooops... Page Not Found!
      </h2>
      <div className="flex justify-center mt-3">
        <a href="/" className="no-underline">
          <button className="text-xl underline font-medium  bg-[#F06939] text-white w-[220px] rounded-md h-[60px] mt-2">
            Go Back
          </button>
        </a>
      </div>
    </div>
  );
};

export default HomeTopBlog;
