import React, { useEffect, useState, Suspense } from "react";
import BackToTop from "../Components/backToTop";
import Base_Url from "../api/baseUrl";
import axios from "axios";
import LazyLoad from "react-lazyload";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";
import decrypt from "../Components/Common/DecryptData";

const CelebrityInsider = React.lazy(() =>
  import("../Components/celebrity/CelebrityInsider")
);
const CelebrityEvents = React.lazy(() =>
  import("../Components/celebrity/CelebrityEvents")
);
const BelowFeaturedImages = React.lazy(() =>
  import("../Components/ads/BelowFeaturedImages")
);

const Celebrity = () => {
  const [celebrityData, setCelebrityData] = useState([]);
  const [celebrity, setCelebrity] = useState([]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  useEffect(() => {
    const fetchCelebrityData = async () => {
      try {
        const [categoryResponse, celebrityResponse] = await Promise.all([
          axios.get(`${Base_Url}api/get-category`),
          axios.get(`${Base_Url}api/get-celebrity?page=1&limit=20`),
        ]);

        const categoryData = categoryResponse.data;
        const celebrityData = celebrityResponse.data;

        const decryptedCategoryString = decrypt(
          categoryData.iv,
          categoryData.end
        );
        const decryptedCategory = JSON.parse(decryptedCategoryString);

        const decryptedCelebrityString = decrypt(
          celebrityData.iv,
          celebrityData.end
        );
        const decryptedCelebrity = JSON.parse(decryptedCelebrityString);

        setCelebrityData(decryptedCategory?.data || []);
        setCelebrity(decryptedCelebrity?.data || []);
      } catch (error) {
        console.error("Error fetching celebrity data:", error);
      }
    };

    fetchCelebrityData();
  }, []);

  return (
    <>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      <div className="px-4">
        <div className="container max-w-7xl mx-auto pt-[2rem] lg:px-0">
          <Suspense>
            <LazyLoad height={200} offset={100}>
              <CelebrityEvents celebrityData={celebrityData} />
            </LazyLoad>
          </Suspense>

          <div className="my-10">
            <Suspense>
              <BelowFeaturedImages />
            </Suspense>
          </div>

          <LazyLoad height={200} offset={100}>
            <CelebrityInsider celebrity={celebrity} />
          </LazyLoad>
        </div>
        <BackToTop />
      </div>
    </>
  );
};

export default Celebrity;
