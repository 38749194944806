import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import BackToTop from "./backToTop";
import BelowFeaturedImages from "./ads/BelowFeaturedImages";
import BetweenContent from "./ads/BetweenContent";
import Base_Url from "../api/baseUrl";
import decrypt from "./Common/DecryptData";

const ViewFinancialIdea = ({ setIdeaLoading, ideaLoading }) => {
  const [financialIdea, setFinancialIdea] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const limit = 10;

  useEffect(() => {
    const ideaView = async () => {
      if (setIdeaLoading) {
        setIdeaLoading(true);
      }
      try {
        const response = await axios.get(
          `${Base_Url}api/getidea?limit=${limit}&page=${page + 1}`
        );
        if (response?.data) {
          const { iv, end } = response.data;

          const decryptedResponse = decrypt(iv, end);

          const parsedData = JSON.parse(decryptedResponse);

          setFinancialIdea(parsedData?.data);
          setPageCount(parsedData?.totalPage);
        }

        setIdeaLoading(false);
      } catch (error) {
        console.log("Error fetching viewIdea data:", error);
      }
    };
    ideaView();
  }, [page, limit, setIdeaLoading]);

  const updateScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 640);
  };
  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);
  return (
    <>
      {/* <LeftSideAd />
      <RightSideAd /> */}
      <div className="">
        {!ideaLoading && (
          <div className="container max-w-7xl mx-auto">
            <h1
              className="flex justify-center text-[#000] md:text-[40px] text-[30px] mt-[3rem] max-[768px]:my-[2rem] font-semibold max-[768px]:text-justify"
              style={{
                fontFamily: "Playfair Display",
              }}
            >
              All Financial Idea
            </h1>
            <div className="my-[3rem] max-[768px]:my-[2rem]">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-9">
                {financialIdea &&
                  financialIdea?.map((idea, index) => {
                    const cardIndex = index + 1;
                    return (
                      <>
                        <div
                          className="px-5 py-3 border border-[#C8C8C8]"
                          key={index}
                        >
                          <div
                            className="sm:flex h-full cursor-pointer"
                            onClick={() => {
                              const formattedTitle = idea?.topic?.replace(
                                /\s+/g,
                                "-"
                              );
                              window.location.href = `/financial-idea/${formattedTitle}`;
                            }}
                          >
                            <div className="flex justify-center">
                              <div className="sm:h-full h-[200px] w-full sm:w-52">
                                <img
                                  src={idea?.image_url[0]}
                                  alt=""
                                  loading="lazy"
                                  className="h-full w-full object-cover"
                                />
                              </div>
                            </div>
                            <div className="w-full mt-2">
                              <div className="sm:ms-6 flex flex-col justify-between h-full">
                                <h3 className="text-black text-[19px] max-[768px]:text-[1.3rem] line-clamp-1 font-semibold">
                                  {idea?.topic}
                                </h3>
                                <p className="line-clamp-2">
                                  {idea?.description}
                                </p>
                                <div className="flex">
                                  <button className="h-8 bg-[#F06939] text-[#fff] px-4 font-bold mt-2 text-sm">
                                    Read More..
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {index === 1 && (
                          <div
                            key={`ad-${cardIndex}`}
                            className="lg:col-span-2 py-5"
                          >
                            {/* <AdResponsive06 /> */}
                            <BelowFeaturedImages />
                          </div>
                        )}
                        {index === 5 && (
                          <div
                            key={`ad-${cardIndex}`}
                            className="lg:col-span-2 py-5"
                          >
                            {/* <AdResponsive /> */}
                            <BetweenContent />
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {!ideaLoading && financialIdea?.length > 10 && (
          <div className="flex justify-center mt-4 my-3 max-[768px]:mt-0">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={isSmallScreen ? 1 : 2}
              pageRangeDisplayed={isSmallScreen ? 1 : 3}
              onPageChange={(e) => {
                setPage(e.selected);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={page}
            />
          </div>
        )}
        <BackToTop />
      </div>
    </>
  );
};
export default ViewFinancialIdea;
