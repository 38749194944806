// StickyAd.js
import React, { useEffect, useRef, useState } from "react";
import SubBlog from "../HomeSubPage/Sub-blog";

const StickyAd = ({ StickyContent, link, url }) => {
  const [isSticky, setIsSticky] = useState(false);
  const endOfArticleRef = useRef(null);
  const subBlogRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (subBlogRef.current) {
        const rect = subBlogRef.current.getBoundingClientRect();
        setIsSticky(rect.top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="lg:w-[30%] lg:block hidden">
      <div className="border border-[#cacaca] my-5" ref={subBlogRef}>
        <SubBlog link={link} url={url} title="Recent Blog" />
      </div>
      <div
        ref={endOfArticleRef}
        className={`${isSticky ? "sticky top-[100px]" : ""}`}
      >
        {StickyContent}
      </div>
    </div>
  );
};

export default StickyAd;
