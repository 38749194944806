import React from "react";
import BackToTop from "../Components/backToTop";
import EndOfArticle from "../Components/ads/EndOfArticle";

const AboutUs = () => {
  return (
    <div className="">
      <img
        src="https://millionairclubs.s3.eu-north-1.amazonaws.com/about-us.webp"
        className="w-[100%] lg:h-[500px] h-[300px]"
        alt="about_img"
      />
      <EndOfArticle />
      <div className="mt-6 container max-w-7xl mx-auto">
        <h1
          className="lg:text-[40px] text-[30px] text-[#000] font-bold flex justify-center"
          style={{ fontFamily: "Playfair Display" }}
        >
          ABOUT US
        </h1>
        <div className="mx-4">
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            Welcome to <b>millionairclubs.in </b> , your premier source for
            comprehensive and up-to-date information on the wealthiest people
            across the globe. Our mission is to provide detailed insights into
            the lives and achievements of the world’s most successful rich
            people.
          </p>
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            On our site, you will find meticulously curated lists of top rich
            individuals, enriched with full descriptions of their business
            ventures, income sources, personal achievements, and other pertinent
            information. We strive to offer a deeper understanding of the
            factors that contribute to their immense wealth and success.
          </p>
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            Our team is dedicated to delivering accurate and engaging content to
            keep you informed and inspired by these financial titans. Whether
            you're a business enthusiast, a student, or simply curious about the
            world's richest, our site offers valuable knowledge and fascinating
            stories.
          </p>
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            We also use cookies to enhance your browsing experience and ensure
            that we provide the best possible service. By continuing to use our
            website, you agree to our use of cookies by our privacy policy.
          </p>
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            Thank you for visiting millionairclubs.in. Stay connected with us to
            explore the journeys of the richest individuals on the planet.
          </p>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default AboutUs;
