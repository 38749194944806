import React from "react";

const Disclaimer = () => {
  return (
    <div>
      <div className="max-w-7xl px-5 mx-auto mb-[20px]">
        <section className="bg-white text-gray-900 py-12 px-2 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <h1
              className="flex justify-center text-[#000] md:text-[37px] font-semibold text-[30px]"
              style={{ fontFamily: "Playfair Display" }}
            >
              Website Disclaimer
            </h1>
            <p className="text-md mb-2 md:text-[18px] text-[16px]">
              We appreciate your visit to our website. We created this platform
              to provide information about our business, the services we offer,
              and useful industry resources.
            </p>
            <p className="text-md mb-2 md:text-[18px] text-[16px]">
              We need to ensure that you completely comprehend the reason for
              this site, which is the reason we have incorporated the
              accompanying disclaimers:
            </p>
            <ul className="list-disc space-y-3  mb-8">
              <li className="text-md md:text-[18px] text-[16px]">
                The sole purpose of this website is for informational purposes.
              </li>
              <li className="text-md md:text-[18px] text-[16px]">
                Our website's content should not be construed as professional
                guidance because it does not.
              </li>
              <li className="text-md md:text-[18px] text-[16px]">
                If you have any specific financial or professional requirements,
                we strongly suggest that you talk to a qualified professional
                advisor. You shouldn't use this website to get personalized
                advice and services.
              </li>
              <li className="text-md md:text-[18px] text-[16px]">
                Getting to this site doesn't lay out an expert relationship or
                understanding between you and our staff. To become a client, a
                formal written agreement is required.
              </li>
              <li className="text-md md:text-[18px] text-[16px]">
                The content of any other websites to which our website may link
                is not under our control. We provide these links as a
                convenience, but we are not responsible for the content or
                accuracy of those external websites.
              </li>
              <li className="text-md md:text-[18px] text-[16px]">
                Case studies and client testimonials on our website are provided
                solely for illustration. These examples do not imply or
                guarantee similar outcomes for all clients, and individual
                results may vary based on particular circumstances.
              </li>
            </ul>
            <p className="text-md md:text-[18px] text-[16px]">
              Thank you for your understanding.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Disclaimer;
